import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Box, Stack, AppBar, Toolbar, IconButton, Tooltip, Badge } from '@mui/material';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GradingIcon from '@mui/icons-material/Grading';
import AddchartIcon from '@mui/icons-material/Addchart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AppsIcon from '@mui/icons-material/Apps';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// material
import { alpha, experimentalStyled as styled } from '@mui/material/styles';
//
import Iconify from '../../components/Iconify';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import { PATH_DASHBOARD } from '../../routes/paths';
import { engineType } from '../../config';
import useAuth from '../../hooks/useAuth';
import axios from '../../utils/axios';
import Logo from '../../components/Logo';
// ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  // backgroundColor: alpha(theme.palette.background.default, 0.72)
  backgroundColor: alpha('#fff', 0.72)
  /* [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
  */
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------
const getRights = (moduleName: string, userRights: any): any => {
  const rights =
    userRights && userRights.Rights.find((element: any) => element.ModuleCode === moduleName);
  return rights;
};

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({ onOpenSidebar }: DashboardNavbarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { userRights, b2bClient } = useAuth();
  const [invoiceCount, setInvoiceCount] = useState<Number>(0);
  const [paymentCount, setPaymentCount] = useState<Number>(0);
  const [tariffCount, setTariffCount] = useState<Number>(0);

  useEffect(() => {
    if (engineType === 'B2B' && b2bClient) {
      axios.get(`GetNotifyCount/${b2bClient.Code}`).then((res) => {
        console.log(res.data.response);
        if (res.data.response) {
          const { PaymentCount, InvoiceCount, TariffCount } = res.data.response;
          setInvoiceCount(InvoiceCount);
          setPaymentCount(PaymentCount);
          setTariffCount(TariffCount);
        }
      });
    }
  }, [b2bClient]);

  /*
  const handleDashboardOpenClick = () => {
    navigate('/wizards/hotel');
  };
  */

  const handleAlertClick = (linkName: string) => {
    let docType: string = '';

    if (linkName == 'P') {
      docType = 'PAYMENT';
      setPaymentCount(0);
    } else if (linkName == 'I') {
      docType = 'INVOICE';
      setInvoiceCount(0);
    } else if (linkName == 'T') {
      docType = 'TARIFF';
      setTariffCount(0);
    }

    axios.get(`setNotifyRead/${docType}`).then((res) => {
      if (res.data.response) {
        linkName === 'P' &&
          location.pathname !== PATH_DASHBOARD.accounts.payments &&
          navigate(`${PATH_DASHBOARD.accounts.payments}?isnotify=true`);
        linkName === 'T' &&
          location.pathname !== PATH_DASHBOARD.reports.tariffnotifications &&
          navigate(`${PATH_DASHBOARD.reports.tariffnotifications}?isnotify=true`);
        linkName === 'I' &&
          location.pathname !== PATH_DASHBOARD.accounts.invoices &&
          navigate(`${PATH_DASHBOARD.accounts.invoices}?isnotify=true`);
      }
    });
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
          <Icon icon={menu2Fill} />
        </IconButton>
        <Logo />
        <Box sx={{ flexGrow: 1 }} />
        {userRights && (
          <Stack direction="row" spacing={{ xs: 0.5, sm: 1.0 }}>
            <Tooltip title="Dashboard">
              <IconButton component={RouterLink} to={PATH_DASHBOARD.menu.root}>
                <DashboardIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Book">
              <IconButton
                disabled={!getRights('Book', userRights).Read}
                component={RouterLink}
                to={PATH_DASHBOARD.booking.searchengine}
              >
                <ShoppingCartIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Manage Bookings">
              <IconButton
                disabled={!getRights('ManBook', userRights).Read}
                component={RouterLink}
                to={PATH_DASHBOARD.managebookings.manageBooking}
              >
                <AppsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Analytics">
              <IconButton
                disabled={!getRights('Report', userRights).Read}
                component={RouterLink}
                to={PATH_DASHBOARD.analytics.root}
              >
                <AddchartIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reports">
              <IconButton
                disabled={!getRights('Report', userRights).Read}
                component={RouterLink}
                to={PATH_DASHBOARD.reports.root}
              >
                <AssessmentIcon />
              </IconButton>
            </Tooltip>
            {engineType === 'B2B' && (
              <>
                <Tooltip title="Tariff">
                  <IconButton onClick={() => handleAlertClick('T')}>
                    <Badge badgeContent={tariffCount} color="error">
                      <NotificationsActiveIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </>
            )}
            {engineType === 'B2B' && (
              <>
                <Tooltip title="Invoices">
                  <IconButton onClick={() => handleAlertClick('I')}>
                    <Badge badgeContent={invoiceCount} color="error">
                      <GradingIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </>
            )}
            {engineType === 'B2B' && (
              <>
                <Tooltip title="Payments">
                  <IconButton onClick={() => handleAlertClick('P')}>
                    <Badge badgeContent={paymentCount} color="error">
                      <ReceiptIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </>
            )}

            <LanguagePopover />
            <AccountPopover />
          </Stack>
        )}
      </ToolbarStyle>
    </RootStyle>
  );
}
