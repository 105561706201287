import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack, Box, Card } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
// import SocialsButton from '../../components/SocialsButton';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Travellect',
    children: [
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
      { name: 'FAQs', href: PATH_PAGE.faqs }
    ]
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: '#' }
    ]
  },
  {
    headline: 'Contact',
    children: [
      { name: 'admin@scalehorizon.com', href: '#' },
      { name: 'Mumbai, India', href: '#' }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#3c3934',
  height: '100%'
}));

// ----------------------------------------------------------------------
export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Stack spacing={1} sx={{ mt: 2, ml: 15, mr: 15, mb: 2 }}>
        <Typography variant="h3" color={'#FFF'} id="contactus">
          Contact Us
        </Typography>
        <Container>
          <Grid container mt={1} spacing={3}>
            <Grid item xs={12} md={4}>
              <Box sx={{ border: 1, borderColor: '#FFF', height: '100%' }}>
                <Stack spacing={1} margin={3}>
                  <Typography variant="h4" color={'#FFF'}>
                    London Office
                  </Typography>
                  <Typography variant="subtitle1" color={'#FFF'}>
                    <LocationOnIcon /> Flotilla House, Battersea Reach, Juniper Dr, London SW18 1FX
                  </Typography>
                  <Typography variant="subtitle1" color={'#FFF'}>
                    <LocalPhoneIcon /> +44 (0)20 7582 2233
                  </Typography>

                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle1" color={'#FFF'}>
                      <EmailIcon />
                    </Typography>
                    <Stack direction="column">
                      <Typography variant="subtitle1" color={'#FFF'}>
                        uk@oceandusk.travel
                      </Typography>
                      <Typography variant="subtitle1" color={'#FFF'}>
                        europe@oceandusk.travel
                      </Typography>
                      <Typography variant="subtitle1" color={'#FFF'}>
                        usa@oceandusk.travel
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ border: 1, borderColor: '#FFF', height: '100%' }}>
                <Stack spacing={1} margin={3}>
                  <Typography variant="h4" color={'#FFF'}>
                    Dubai Office
                  </Typography>
                  <Typography variant="subtitle1" color={'#FFF'}>
                    <LocationOnIcon /> 701, JBC5, Cluster W, JLT, P.O. Box 390591, Dubai, UAE
                  </Typography>
                  <Typography variant="subtitle1" color={'#FFF'}>
                    <LocalPhoneIcon /> +971 (0)4 450 8225
                  </Typography>

                  <Typography variant="subtitle1" color={'#FFF'}>
                    <EmailIcon /> uk@oceandusk.travel
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ border: 1, borderColor: '#FFF', height: '100%' }}>
                <Stack spacing={1} margin={3}>
                  <Typography variant="h4" color={'#FFF'}>
                    Connect
                  </Typography>
                  <Typography variant="subtitle1" color={'#FFF'}>
                    <LocalPhoneIcon /> +971507618948
                  </Typography>
                  <Typography variant="subtitle1" color={'#FFF'}>
                    <EmailIcon /> win@odos.travel
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Stack>
    </RootStyle>
  );
}
