import { m } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
// import { capitalCase } from 'change-case';
import { Link as RouterLink, useLocation, Outlet } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Container, Typography, Grid } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
// import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import MainFooter from '../../layouts/B2B/MainFooter';
import MainHeader from '../../layouts/B2B/MainHeader';
import MainDeals from '../../layouts/B2B/MainDeals';
import { endPoints } from '../../config';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

const getCompanyDomain = (hostName: string) => {
  if (hostName === 'localhost') {
    return 'OceanDusk';
  }

  const subDomain = hostName.split('.');
  if (subDomain[0] === 'backoffice') {
    return 'ScaleHorizon';
  } else if (subDomain[0] === 'odos') {
    return 'OceanDusk';
  }
};

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 20,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%'
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const HeroOverlayStyle = styled(m.img)({
  zIndex: 9,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const HeroImgStyle = styled(m.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  width: '100%',
  margin: 'auto',
  position: 'absolute',
  [theme.breakpoints.up('lg')]: {
    right: '8%',
    width: 'auto',
    height: '48vh'
  }
}));

// ----------------------------------------------------------------------

export default function LoginB2B() {
  const navigate = useNavigate();
  // const { method } = useAuth();
  const endPoint =
    process.env.NODE_ENV === 'production' ? endPoints.prodCDNEndPoint : endPoints.prodCDNEndPoint;

  const companyName = getCompanyDomain(window.location.hostname);

  let companyLogo = `${endPoint}/assets/images/home/CompanyLogo.svg`;
  let bgColor = '#142c64';
  if (companyName === 'OceanDusk') {
    companyLogo = `${endPoint}/assets/images/home/od_brown_logo.jpeg`;
    bgColor = '#343331';
  }
  const showBGImage = false;

  return (
    <RootStyle>
      <MainHeader />
      <Box
        sx={{
          position: 'relative',
          backgroundImage: `url(${endPoint}/assets/images/home/od_banner.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          objectFit: 'cover',
          height: '100vh',
          minHeight: '100vh',
          width: '100%'
        }}
      >
        <Grid container sx={{ pt: 20, pl: 5, pr: 5 }}>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={4} />

          <Grid item xs={12} md={4} />
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 5 }}>
              <Stack
                direction="column"
                alignItems="center"
                sx={{ mb: 5, backgroundColor: bgColor }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 130,
                    width: 250,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  src={companyLogo}
                  onClick={() => navigate('/')}
                />
              </Stack>
              <LoginForm />
            </Card>
          </Grid>
          <Grid item xs={12} md={4} />
        </Grid>
      </Box>
      <MainDeals />
      <Box sx={{ flexGrow: 1 }} />
      <MainFooter />
    </RootStyle>
  );
}
